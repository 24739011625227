/* new   */
#FundChart {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
}


#FundChart.hide {
    display: none;
}


#FundChart.show {
    display: block;
}