#overlay {
    top:0;
    position: fixed;
    width: 100vw;   
    height: 100vh;
    z-index: 999; 
    background-color : rgba(0,0,0,0.2);
}

#overlay.show {
  display: block;
}

#overlay.hide {
   display: none;
}

#header {
    position:fixed;
    top:0px;
    left:0px;
    right:0px;
    background-color: white;
    -webkit-box-shadow: 5px 0px 8px 2px gray;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 5px 0px 8px 2px gray;  /* Firefox 3.5 - 3.6 */
    box-shadow: 5px 0px 8px 2px gray;  /* Opera 10.5, IE 9, Firefox 4+, C */
    margin-bottom: 10px;
}

#title {
    padding-left: 0.5em;
    padding-bottom: 0.5em;
    background-color: white;
}

 #title span {
    font-family: 'exo2-bolditalic';
    font-size: 2.1em;
    color: #333;
  }

#title img {
      position: absolute;
      height:2em;
      width:2em;
      top:8px;
      right:0.5em;
  }

#subtitle {
  font-family: 'Exo2-BoldItalic';
  font-size: 1.2em;
  color: #333;
  background-color: orange;
  padding-left: 0.5em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 
#header:after {
    content:""; 
    position:absolute;
    width:100%;
    bottom:1px;
    z-index:-1;
    box-shadow: 8px 0px 8px 2px #000000;
} */
