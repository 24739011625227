body {
  background-color: white;
  margin: 0;
  font-family: exo2-regular;
  color: #222;
  overflow: auto;
}

#pagewrapper {
  line-height: 1.4;
  margin-top: 100px;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

#pagewrapper h1 {
  background-color: #eee;
  margin-top: 1em;
  margin-bottom: 0.25em;
  font-family: 'exo2-medium';
  font-size: 1.5em;
  padding-left: 0.2em;
}

#pagewrapper h2 {
  background-color: #eee;
  margin: 0px 2px 0px 2px;
  font-family: 'exo2-medium';
  font-size: 1.5em;
  padding-left: 0.2em;
}

#pagewrapper .backbutton {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  left: 0px;
  background-color: white;
  box-shadow: 5px 0px 8px 2px gray;
  text-align: center;
}

#pagewrapper .backbutton>button {
  border-radius: 5px;
  border-width: 0;
  background-color: orange;
  box-shadow: 2px 2px 4px 1px lightgrey;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 5px 10px 5px 10px;
  font-family: 'Exo2-SemiBold';
  font-size: 1em;
  color: #222;
}



@font-face {
  font-family: "exo2-regular";
  src: url(../fonts/exo2-regular.eot);
  src: url(../fonts/exo2-regular.eot?#iefix) format('embedded-opentype'),
    url(../fonts/exo2-regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "exo2-bolditalic";
  src: url(../fonts/exo2-bolditalic.eot);
  src: url(../fonts/exo2-bolditalic.eot?#iefix) format('embedded-opentype'),
    url(../fonts/exo2-bolditalic.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "exo2-medium";
  src: url(../fonts/exo2-medium.eot);
  src: url(../fonts/exo2-medium.eot?#iefix) format('embedded-opentype'),
    url(../fonts/Exo2-Medium.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "exo2-semibold";
  src: url(../fonts/exo2-semibold.eot);
  src: url(../fonts/exo2-semibold.eot?#iefix) format('embedded-opentype'),
    url(../fonts/Exo2-SemiBold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* 
@font-face {
  font-family  : "Exo2-Italic";   
  src : url(../fonts/exo2-Italic.eot);
  src : url(../fonts/exo2-Italic.eot?#iefix) format('embedded-opentype'),
        url(../fonts/Exo2-Italic.ttf) format('truetype');
  font-weight : normal;
  font-style  : normal;
}





 */