#pagewrapper.login-container {
      padding: 25px;
} 


#loginform .resetpassword {
    float: right;
}


#loginform .resetpasswordbutton {
    color: #20A6DB;
    background-color: transparent;
    padding:0px;
    cursor: pointer;
    margin: 0px;
    font-family: exo2-regular;
    font-size: 1em;
    border: none;
}


.loginInput {
    width: 100%;
    padding: 10px 18px;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-top: 8px;
    font-size: 1.1em;
}

.loginInput.emailInput {
    text-transform: lowercase;
}

.submitButton {
    background-color: #d32d27;
    color: white;
    padding: 14px 20px;
    margin: 30px 0 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 1.1em;
}

.submitButton:disabled {
  background: #999;
  color: #555;
  cursor: not-allowed;
}

#password {
    margin-bottom: 5px;
}


#login:hover {
    opacity: 0.8;
}

#login:focus {
    outline: none;
}


#loginform .signup {
    text-align: center;
}

#loginform .signup a {
    text-decoration: none;
    color: #20A6DB;
}

#loginform .signup {
    outline: none;
}

#loginform .createaccount {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
    padding-top: 10px;
    padding-bottom: 10px;
    width: 80%;
    margin: auto;
}

#loginform .createaccount > span {
    position: relative;
    display: inline-block;
    color: gray;
    font-size: 0.9em;
}

#loginform .createaccount > span:before,
#loginform .createaccount > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: gray;
}

#loginform .createaccount > span:before {
    right: 100%;
    margin-right: 15px;
}

#loginform .createaccount > span:after {
    left: 100%;
    margin-left: 15px;
}

.text-danger {
    padding-bottom: 10px;
    color: #d32d27;
    height: 1em;
    margin-top: 3px;
}
