#flyoutMenu {
  width: 250px;   
  height: 100vh;
  background-color: #111;
  position: fixed;
  top: 0;
  right: 0;
  padding-top: 40px;
  transition: transform 0.5s
              cubic-bezier(0, .52, 0, 1);
  white-space: nowrap;
  overflow-x: hidden;
  z-index: 1000;
}
 
#flyoutMenu.hide {
  transform: translate3d(250px, 0, 0); 
}

#flyoutMenu.show {
  transform: translate3d(0vw, 0, 0);   
  overflow: hidden;
}

#flyoutMenu ul {
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

#flyoutMenu a {
    padding-left: 32px;
    text-decoration: none;
    color: #ffffff;
    font-size: 1.2em;
}

#flyoutMenu li {
  padding-top: 10px;
  padding-bottom: 10px;
}

    #flyoutMenu li.active {
        background-color: gray;
    } 



#flyoutMenu li hr {
  border: 0px;
  border-top: 1px solid white;
  margin: 0px 32px;
}
