.price-up {
    color: #4aa00f;
}


.price-down {
    color: #e51e17;

}

.price-down img,
.price-up img {
    height: 15px;
    margin-left: 3px;
    vertical-align: text-top;
}

.trend-indicator {
    height: 19px !important;
}

.bold-text {
    font-family: 'Exo2-SemiBold';
}

.price-52-week-high {
    color: white;
    background-color: #4aa00f;
    padding-left: 10px;
    padding-right: 10px;
}