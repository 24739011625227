#table {
    width: 100%;
}

#table tr th {
    font-family: 'Exo2-Medium';
    background-color: #eee;
    text-align: left;
    padding: 10px 3px 1px;
}

#table tr th div {
    width: 355px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#table tr td {
    padding: 5px;
    font-size: 0.85em;
}

#table tr.sellalert {
    background-color: #e51e17;
    color: white;
}

#table tr td.key {
    vertical-align: middle;
    width: 30%;
    text-align: right;
    font-family: 'Exo2-SemiBold';
}

#table tr td.value {
    width: 70%;
}

#table tr td p {
    margin: 0px;
    display: flex;
    align-items: center;
}

#table tr td p img {
    margin-right: 5px;
    float: left;
    height: 30px;
    border: 0;
    vertical-align: middle;
}