.editportfolio span {
    font-size: 1.2em;
}


.editportfolio img {
    cursor: pointer;
    float: right;
    height: 30px;
}
