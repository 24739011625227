#pagewrapper.requiredYield {
    margin-top: 145px;
}

#yield {
    position:fixed;
    top: 80px;
    left: 0px;
    width: 100%;
    font-family: exo2-medium;
    overflow: hidden;
}

#yield .shadow {
    background-color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    -webkit-box-shadow: 5px 0px 8px 2px gray;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 5px 0px 8px 2px gray;  /* Firefox 3.5 - 3.6 */
    box-shadow: 5px 0px 8px 2px gray;  /* Opera 10.5, IE 9, Firefox 4+, C */
    margin-bottom: 10px;
}

#yield img {
    vertical-align: middle;
    height: 30px;
}

#yield .labelYield {
    padding-right: 0.5em;
    padding-left: 0.5em;
}

#yield .requiredYield {
    border: 1px solid grey;
    padding: 4px;
    vertical-align: middle;
    width: 45px;
    text-align: center;
    display: inline-block;
    margin-right: 0.5em;
    margin-left: 0.5em;
}

#yield .button {
  border-radius: 15px; 
  box-shadow: 2px 2px 4px 1px gray;
}

#yield .button:active {
  background-color:lightblue;
  transform: translateY(2px);
}