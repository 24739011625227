
.react-autosuggest__input {
    font-family: Exo2-Regular;
    font-size: 1em;
    padding: 7px 5px;
    border: none;
    width: 100%;
    outline: none;
}

.react-autosuggest__container {
    position: fixed;
    left:40px;
    right: 10px;
    top: 80px;
}

.react-autosuggest__suggestions-list {
    position: fixed;  
    top: 110px;
    left: 0px;
    right: 20px;
}

.react-autosuggest__suggestion {
    padding: 5px 10px 5px 10px;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 5px;
    background-color: white;
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 4px 1px gray;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 2px 2px 4px 1px gray;  /* Firefox 3.5 - 3.6 */
    box-shadow: 2px 2px 4px 1px lightgrey;  /* Opera 10.5, IE 9, Firefox 4+, C */
}

.react-autosuggest__suggestion strong {
    color: #3399FF;
}


#pagewrapper.searchpanel {
    margin-top: 130px;
}


#search {
    position:fixed;
    top: 80px;
    left: 0px;
    right: 0px;
    font-family: exo2-medium;
    overflow: hidden;
}

#search .shadow {
    background-color: white;
    -webkit-box-shadow: 5px 0px 8px 2px gray;  
    -moz-box-shadow: 5px 0px 8px 2px gray; 
    box-shadow: 5px 0px 8px 2px gray;  
    margin-bottom: 10px;
}

#search img {
    height: 20px;
    top: 0px;
    left: 0px;
    margin-top: 8px;
    margin-left: 10px;
    margin-bottom: 6px;
}
