#statuswarning {
    width: 100%;
    background-color: red;
    text-align: center;
    font-family  : "Exo2-SemiBold";
    font-size: 2em;
    color: #fefefe!important;
    margin-bottom: 20px;
}

#statuswarning.show {
    display: block;
}

#statuswarning.hide {
    display: none;
}
