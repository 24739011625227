#pagewrapper.info {
     margin-left: 8px;
     margin-right: 8px;
     margin-bottom: 30px;
}


#pagewrapper.info h3 {
     margin-top: 1em;
     margin-bottom: 0.25em;
     font-family: 'exo2-semibold';
     font-size: 1em;
}